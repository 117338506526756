@import "consts";

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  line-height: 1.725;
  text-rendering: geometricPrecision;
  // font-family: "Helvetica Neue", sans-serif;
  font-family: "Menlo", "Meslo LG", monospace;
  font-size: 1em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background-color;
}

html {
  height: 100%;
  width: 100%;
}

.main-container {
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 620px;
}
@media (max-width: 640px) {
  .main-container {
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 300px;
  }
}
.section-container {
  .section-item {
    display: block;
    margin-top: 40px;
    // background-color: grey;
  }
  .subsection-container {
    a {
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid #000;
    }
    a:hover {
      border-bottom: 1px solid $links-color;
    }
    .subsection-title {
      font-size: $title-font-size;
      color: $title-color;
      font-weight: $title-font-weight;
      text-transform: capitalize;
    }
    .subsection-content {
      // text-align: justify;
      text-justify: inter-word;
      p {
        // font-size: $section-content-font-size;
      }
      h1 {
        margin-top: 20px;
      }
      h2 {
        margin-top: $subsection-content-h2-margin-top;
        font-size: $section-title-font-size;
        // color: $title-color;
        font-weight: $section-title-font-weight;
      }
      ul {
        li {
        }
      }
    }
    .subsection-footnote {
      margin-top: 10px;
      color: grey;
      font-size: $footnote-font-size;
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
