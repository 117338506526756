@import "/src/Sections/consts.scss";

.title-container {
  min-height: $img-height;
  display: flex;
  flex-direction: row;
  .title-img-container{
    // height: $img-height;
    // overflow: hidden;
    // display: inline-block;
    .title-img {
      background-repeat: repeat-x;
      background-position: bottom;
      background-size: 100% 6px;
      // float: left;
      margin-right: 20px;
      // width: $img-width;
      height: $img-height;
      background-repeat: no-repeat;
      // background-size: 43px $img-height;
      border-radius: 5px;
      -webkit-filter: grayscale(20%);
      filter: grayscale(20%);
    }
    .hovered {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
  .title-content-container{
    // display: inline-block;
    // height: $img-height;
    // overflow: hidden;
    .title-name {
      font-size: $h1-font-size;
      font-weight: 550;
      font-family: "Menlo", "Meslo LG", monospace;
    }
    .title-links {
      margin: 0;
      button {
        padding: 0;
        // padding-bottom: 5px;
        border: none;
        background-color: transparent;
        margin-right: 5px;
        text-decoration: none;
        color: $links-color;
        font-size: 0.8em;
        border: 1px solid $background-color;
      }
      button.selected {
        padding: 1px 5px 1px 5px;
        // box-shadow: 0px 3px $links-color;
        // border: 1px solid $links-color;
        background-color: $links-color;
        color: $background-color;
        border-radius: 5px;
        text-decoration: none;
        // border: 1px solid $links-color ;
      }
      button:hover:not(.selected) {
        cursor: pointer;
        opacity: 0.6;
      }
      // button + button::before {
      //   content: "  |  ";
      // }
    }
  }
}
