$font-size-base: 1rem;

$img-height: 70px;
$img-width: 48px;

$page-content-width: 46rem;

$background-color: #e2e0de;

$links-color: #d44375;
$links-color-faded: $links-color + "40";
$title-color: #d44375;

$title-font-size: 1.5em;
$title-font-weight: 700;

$section-title-font-size: 1.3em;
$section-title-font-weight: 600;

// $section-content-font-size: 0.9em;
// $section-content-font-weight: 300;

$subsection-content-h2-margin-top: 40px;

$footnote-font-size: 0.7em;

$h1-font-size: 1.75 * $font-size-base;
$h2-font-size: 1.5 * $font-size-base;
$h3-font-size: 1.25 * $font-size-base;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;
