@import "/src/Sections/consts.scss";

.pubs-list {
  margin-top: $subsection-content-h2-margin-top;
  font-size: 0.8em;
  .pubs-item {
    background-color: #302d2a;
    margin-top: 15px;
    padding: 10px;
    border-radius: 5px;

    .pubs-item-index {
      color: #fff;
      float: left;
      width: 30px;
    }
    .pubs-item-content {
      margin-left: 30px;
      .pubs-title {
        color: #a2fca2;
        a {
          color: #a2fca2;
          text-decoration: none;
          border-bottom: none;
        }
        a:hover {
          border-bottom: 1px solid $links-color;
        }
      }
      .pubs-venue {
        color: #d6d6d6;
        font-style: italic;
      }
      .pubs-year {
        font-style: italic;
      }
      .pubs-conference {
        color: #fcc28c;
      }
      .pubs-award {
        color: #FFD700;
        // font-weight: 600;
      }
      .pubs-authors {
        color: #fff;
        .author-highlight {
          font-weight: 600;
        }
        span + span::before {
          //   display: inline-block;
          //   white-space: pre;
          content: ", ";
        }
      }
    }
  }
}
