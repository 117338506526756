@import "/src/Sections/consts.scss";

.footer-container {
  width: 100%;
  height: 80px;
  align-items: center;
  color: #666;
  margin: 0 auto 1rem auto;
  vertical-align: center;
  text-align: center;
  border-top: 1px solid rgb(172, 172, 172);
  .footer-content {
    // width: 50%;
    font-size: 0.7em;
    margin-bottom: 50px;
    margin-top: 20px;
  }
}
